import React from 'react';
import { Link } from "react-router-dom";
import '../styles/imagenUno.modules.css'
import verdenaranja from '../styles/verdenaranja.png'
import flechaDerecha from '../styles/flechaDerecha.png';
import flechaIzquierdaReel from'../styles/flecha-izquierda.png';
import flechaDerechaReel from '../styles/flecha-derecha.png'
import reelUno from '../styles/reel/1.jpg'
import reelDos from '../styles/reel/2.jpg'
import reelTres from '../styles/reel/3.jpg'
import reelCuatro from '../styles/reel/4-5.jpg'
import reelCinco from '../styles/reel/5.jpg'
import reelSeis from '../styles/reel/6.jpg'
import { useState } from 'react';
import  primera from '../styles/1-istockphoto-primera.jpg'
import  segunda from '../styles/2-istockphoto-segunda.jpg'
import  tercera from '../styles/3-istockphoto-tercera.jpg'
import  cuarta from '../styles/4-istockphoto-cuarta.jpg'
import  quinta from '../styles/5-istockphoto-quinta.jpg'
function MainPage() {
  const [contadorClicks, setContadorClicks]=useState(0)



   // MANEJADOR SLIDER IZQUIERDO, CON SELECCION GENERAL 
   const handleSliderLeft = (event) => {
    event.preventDefault(); // Evita que la página se desplace hacia arriba
    const buttonIdSlider = event.target.id;
    sliderLeft(buttonIdSlider);
  };


const sliderLeft = (buttonIdSlider) => {
    const slider = document.getElementsByClassName("zona-reel-del-dia");
    const image = slider[0].querySelector("img");
  
    if (contadorClicks !== 0) {
      setContadorClicks(contadorClicks - 1);
      slider[0].scrollLeft -= image.offsetWidth;
    }
  };

  function handleSliderLeftReset(event) {
    event.preventDefault(); // Evita que la página se desplace hacia arriba
    var slider = document.getElementsByClassName("zona-reel-del-dia");
    slider[0].scrollLeft -= 7000;
  }

  // MANEJADOR SLIDER DERECHO, CON SELECCION GENERAL 
  const handleSliderRight = (event) => {
    event.preventDefault(); // Evita que la página se desplace hacia arriba
    if (contadorClicks >= 5) {
      setContadorClicks(0);
      handleSliderLeftReset(event);
    } else {
      sliderRight();
    }
  };


const sliderRight = () => {
    const slider = document.getElementsByClassName("zona-reel-del-dia");
    const image = slider[0].querySelector("img");
  
    slider[0].scrollLeft += image.offsetWidth;
    setContadorClicks(contadorClicks + 1);
  };

    return (
<div className='main-main-page'>
 <div className='main-main-page-contenido'>
       
        <div className="image-main-1">
                <h1 className="img-txt-main1">Especias & Vegetales</h1>
                <h1 className="mainTextMP1">Deshidratados.</h1>
                <img className='image-main-1-img' src={primera}alt='condimentos-primer-imagen'></img>
        </div>
 
        <div className="image-main-2">
                <h1 className="img-txt-main2">Sabores para deleitar tus sentidos</h1>
                <h1 className="mainTextMP2">Regala momentos inolvidables: Encuentra la receta perfecta para compartir.</h1>
                <img className='image-main-2-img' src={segunda}alt='condimentos-segunda-imagen'></img>
        </div>

        <div className="image-main-3" >
                    <h1 className="img-txt-main3">Más sabor</h1>
                    <div className='img-txt-main3-links'>
                        <Link to="/deshidratado" className="link-deshidratados">Aprender más<img src={flechaDerecha} alt='flecha-hacia-derecha' className='img-txt-main3-links-flecha-derecha'></img></Link>
                    </div>
                    <img className='image-main-3-img' src={tercera}alt='condimentos-tercera-imagen'></img>
        </div>

        <div className="image-main-4" >
                <div className='image-main-4-izquierda'>
                        <h1 className="img-txt-main4">Descubre, renueva y ahorra</h1>
                        <h1 className="mainTextMP4">Delicias vegetales y especias deshidratadas al alcance de tu mano.</h1>
                        <img className='image-main-4-img' src={cuarta}alt='condimentos-cuarta-imagen'></img>
                </div>

                <div className='image-main-4-derecha'>
                        <h1 className="img-txt-main4-derecha">Delicioso, versátil, irresistible</h1>
                        <img className='image-main-5-img' src={quinta}alt='condimentos-quinta-imagen'></img>
                </div>    
        </div>

        <div className='main-zona-imagenes-reel' >
            
        <div className='zona-reel-del-dia'>
                                                    <img src={reelSeis} className='imgs-reel' alt='img-reel'></img>
                                                    <img src={reelDos} className='imgs-reel' alt='img-reel'></img>
                                                    <img src={reelTres} className='imgs-reel' alt='img-reel'></img>
                                                    <img src={reelCuatro} className='imgs-reel' alt='img-reel'></img>
                                                    <img src={reelCinco} className='imgs-reel' alt='img-reel'></img> 
                                                    <img src={reelUno} className='imgs-reel' alt='img-reel'></img>
        </div>


            <div className='botones-main-reel'>
              <div className='boton-izquierdo-container'>
                <button onClick={handleSliderLeft} className='boton-flecha-izquierda-scroll-bar-reel' id='1'>
                  <img src={flechaIzquierdaReel} className="flecha-izquierda-scroll-reel" alt='flecha-izquierda-scroll-reel'></img>
                </button>
              </div>

              <div className='boton-derecho-container'>
                <button onClick={handleSliderRight} className='boton-flecha-derecha-scroll-bar-reel'>
                  <img src={flechaDerechaReel} className="flecha-derecha-scroll-reel" alt='flecha-derecha-scroll-reel'></img>
                </button>
              </div>
            </div>
                                            
        </div>  
  </div>

            <footer className='pie-main-main-main'>
                  <img className="img-pie-main-main" src={verdenaranja} alt='verder_naranja'></img>
                <div className='txt-footer-contenedor-txt-main'>
                  <h1 className='txt-txt-main-main-main'>© 2023 Ocar Hermanos S.R.L. | Todos los derechos reservados</h1>
                </div>
            </footer>




</div>
    );
}

export default MainPage;