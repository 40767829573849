import React from "react";
import '../styles/DeshidratadoPage.modules.css'
import verdenaranja from '../styles/verdenaranja.png'
import degrade from '../styles/degrade.png'
import airesCampo from '../styles/livianoMasCorto.mp4'
import DSC from '../styles/DSC.jpg'
import DSC2 from '../styles/DSC2.jpg'
import DSC4 from '../styles/DSC4.jpg'
function DeshidratadoSeleccion(){

      // SCROL AUTOMATICO A TOP PAGE 
      const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };  
      scrollToTop();


return(
    <>
      <div className="mainDeshidratado">

        <div class="container-deshidratado">
                      <div className='video_contenedor' >
                      <video className='video' autoPlay loop>
                          <source src={airesCampo} type="video/mp4" autoplay/>
                      </video>
                  </div>
      
      
              <div class="item1-deshidratado">
                
                  <div className="txt-Deshidratado-zone">
                    <h1 className="txt-Deshidratado-1">Selección: Nuestro proceso de deshidratación se lleva a cabo con mucho cuidado y precisión para garantizar que los vegetales y especias conserven su sabor y aroma originales. Primero, seleccionamos cuidadosamente los ingredientes más frescos y de la más alta calidad. Luego, los cortamos en trozos pequeños y los extendemos en bandejas especiales para deshidratación. </h1>
                  </div>
                  <img className="imagen1-deshidratado"src={DSC4} alt="img"></img>
              </div>

            <img className="degrade1"src={degrade} alt="degradeLine"></img>





              <div class="item2-deshidratado">
                <img className="imagen2-deshidratado"src={DSC2} alt="img"></img>
                  <div className="txt-Deshidratado-zone">
                    <h1 className="txt-Deshidratado-2">Deshidratación: Una vez que los ingredientes están, los colocamos en un horno de deshidratación de alta tecnología. Este horno elimina suavemente la humedad de los ingredientes sin alterar su sabor o textura. La temperatura y el tiempo de deshidratación son monitoreados de cerca para asegurarnos de que se realice de manera uniforme y completa.</h1>
                </div>
              </div>
            <img className="degrade2"src={degrade} alt="degradeLine"></img>




            <div class="item3-deshidratado">

              <div className="txt-Deshidratado-zone">
                  <h1 className="txt-Deshidratado-3">Empaque: Una vez que los ingredientes están completamente deshidratados, los empaquetamos cuidadosamente en bolsas especiales para mantener su frescura y sabor. La deshidratación ayuda a prolongar la vida útil de los ingredientes sin necesidad de agregar conservantes o químicos dañinos, lo que los convierte en una opción saludable y segura para usar en la cocina</h1>
              </div>
              <img className="imagen3-deshidratado"src={DSC} alt="img"></img>
            </div>
      


            <footer className='pie-main-deshidratado'>
                    <img className="img-pie-main-deshidratado" src={verdenaranja} alt='linea-naranja-verde'></img>
                    <div className='txt-footer-contenedor-txt-deshidratado'>
                    <h1 className='txt-txt-main-deshidratado'>© 2023 Ocar Hermanos S.R.L. | Todos los derechos reservados</h1>
                    </div>      
          </footer>

        </div>


      </div>
    </>
)
}
export default DeshidratadoSeleccion;