
import React from "react";
import { Link } from "react-router-dom";
import "../styles/main.css";
import logo from '../styles/logo.png'
import { FaBars, FaTimes} from "react-icons/fa"
import {useRef} from "react";


function NavBar(){
  const navRef= useRef();
  const showNavbar = ()=>{
    navRef.current.classList.toggle("responsive_nav");
  }
  const hideNavbar =()=>{
    navRef.current.classList.remove("responsive_nav")
  }


  return(
    <header id="h" className="header">
        <Link to="/"><img className="logoNav" src={logo} alt="logo" width="140px" height="48px"></img></Link>
          <div className="nav-nav-container">
            <nav ref={navRef}>
      
          
                    <button className="nav-btn nav-close-btn" onClick={hideNavbar}>
                  <FaTimes/>
                </button>
                  <div className="links-nav-menu-desplegable">
                  {/* <Link to="/acercade" onClick={hideNavbar}>Acerca de</Link> */}
                  
                  <Link to="/deshidratado" onClick={hideNavbar}>Deshidratado</Link>
                  
                  <Link to="/productos" onClick={hideNavbar}>Productos</Link>
                  </div>

            </nav>
            </div>
        <button onClick={showNavbar}>
          <FaBars/>
        </button>
    </header>
  )
}

export default NavBar;


