// import { Link } from "react-router-dom";
import React from "react";
import '../styles/ProductosSeleccion.modules.css'
import verdenaranja from '../styles/verdenaranja.png'
import espinaca2 from '../styles/espinaca-2.jpg'
import espinacaEscama from '../styles/espinaca-en-escamas_1.jpg'
import nuezMoscada from '../styles/nuez-moscada-grano.jpg'
import degrade from '../styles/degrade.png'
function ProductosSeleccion(){

      // SCROL AUTOMATICO A TOP PAGE 
      const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };  
      scrollToTop();


return(
    <>
      <div className="mainProductos">

        <div class="container">

              <div class="item1">
                <img className="imagen1"src={espinaca2} alt="img"></img>
                  <div className="txt-Productos-zone1">
                    <h1 className="txt-Productos-1">Deshidratados en polvo</h1>
                       <button className="link-producto-polvo">Próximamente lista</button>
                  </div>
              </div>

            <img className="degrade-seleccion-productos"src={degrade} alt="degradeLine"></img>





              <div class="item2">
                <img className="imagen2"src={espinacaEscama} alt="img"></img>
                  <div className="txt-Productos-zone2">
                    <h1 className="txt-Productos-2">Deshidratados en escamas</h1>
                       <button className="link-producto-polvo2">Próximamente lista</button>
                </div>
              </div>
            <img className="degrade2-seleccion-productos"src={degrade} alt="degradeLine"></img>




            <div class="item3">
            <img className="imagen3"src={nuezMoscada} alt="img"></img>
              <div className="txt-Productos-zone3">
                  <h1 className="txt-Productos-3">Productos estandar</h1>
                <button className="link-producto-polvo3">Próximamente lista</button>
              </div>
            </div>
      
        </div>

          <footer className='pie-main-seleccion-productos'>
                    <img className="img-pie-seleccion-productos" src={verdenaranja} alt=''></img>
                    <div className='txt-footer-contenedor-txt-seleccion-productos'>
                    <h1 className='txt-txt-main-seleccion-productos'>© 2023 Ocar Hermanos S.R.L. | Todos los derechos reservados</h1>
                    </div>      
          </footer>

      </div>
    </>
)
}
export default ProductosSeleccion;