import './App.css';
import React from 'react';
import {  Route } from 'react-router-dom'
import NavBar from './components/NavBar';
import ProductosSeleccion from './components/ProductosSeleccion'
import MainPage from './components/MainPage';
import DeshidratadoPage from './components/DeshidratadoPage'

function App() {

  return (
    
    <div className="App">
   <Route path='/' component={NavBar} />
   <Route exact path="/" component={MainPage}/>

   <Route  exact path='/productos' component={ProductosSeleccion} />
   
   <Route exact path='/deshidratado' component={DeshidratadoPage} />

   {/* <Route exact path='/especias' component={spicisPage} /> */}

   {/* <Route exact path='/acercade' component={aboutPage} /> */}

   {/* <Route exact path='/productos/polvo' component={seleccionPowder} /> */}
  
   
{/*    
  
    <Route exact path='/' component={Home} />
      <Switch>

      <Route  exact path='/deshidratado' component={DeshidratadoInfo} />

      <Route  exact path='/polvo' component={polvoInfo} />

      <Route  exact path='/estandar' component={estandarInfo} />


      <Route  exact path='/productos' component={ProductosSeleccion} />
      {/* <Route exact path='/productos/polvo' component={polvoProductos} /> */}
      {/* <Route path='/productos/escamas' component={escamasProductos} /> */}
      {/* <Route path='/productos/estandar' component={estandarProductos} /> */}
{/* 
      <Route exact path='/productos/polvo/:id' component={DetalleProducto} />
      <Route path='/productos/escamas/:id' component={DetalleProducto} />
      <Route path='/productos/estandar/:id' component={DetalleProducto} />

      </Switch>*/}
    </div>
  );
}

export default App;



